import React from "react"

import CrossFadeImage from "./crossfade"
import SS_1 from "../images/1.jpg"
import SS_2 from "../images/2.jpg"
import SS_3 from "../images/3.jpg"

const sliderImages = [SS_1, SS_2, SS_3]

class Slider extends React.PureComponent {
  state = {
    currentIndex: 0,
  }

  componentDidMount = () => {
    this.slideTimer = setInterval(this.slide, 5000)
  }

  componentWillUnmount = () => {
    clearInterval(this.slideTimer)
  }

  slide = () => {
    const { currentIndex } = this.state
    const nextIndex = currentIndex === 2 ? 0 : currentIndex + 1

    this.setState({ currentIndex: nextIndex })
  }

  render() {
    const { currentIndex } = this.state

    return (
      <div
        style={{
          margin: 30,
          marginTop: 40,
          width: `300px`,
          position: "relative",
        }}
      >
        <CrossFadeImage src={sliderImages[currentIndex]} />
      </div>
    )
  }
}

export default Slider
