import React from "react"
import PropTypes from "prop-types"

import { COLORS } from "../styles/constants"

const Footer = ({ siteTitle }) => (
  <footer
    style={{
      padding: "1rem",
      backgroundColor: COLORS.lightGray,
    }}
  >
    <div
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "space-between",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 240px))",
        padding: "1rem 2rem",
        fontSize: ".85rem",
      }}
    >
      <div style={{ color: COLORS.blue, fontWeight: 700 }}>
        <a style={{ textDecoration: "none" }} href="mailto:hello@mobabuild.co">
          Contact Us
        </a>
      </div>

      <div style={{ color: COLORS.gray }}>
        © {new Date().getFullYear()}
        {` `}
        {siteTitle}
      </div>
    </div>
    <p style={{ color: COLORS.gray, fontSize: ".2rem", textAlign: "center" }}>
      Mobabuild isn’t endorsed by Riot Games and doesn’t reflect the views or
      opinions of Riot Games or anyone officially involved in producing or
      managing League of Legends.League of Legends and Riot Games are
      trademarks or registered trademarks of Riot Games, Inc. League of Legends
      © Riot Games, Inc.
    </p>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
