import React from "react"

import feature from "../images/feature.png"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const Content = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader title="An application for players made by a player." />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <p style={{ color: COLORS.gray }}>
          Mobabuild is the application for you to follow League of Legends pro
          builds and guides seamlessly. All the skillful and challenger players
          around the world have own spell, rune, item and skill combinations to
          dominate games. We made it easy for you to reach these tactics and use
          it on your champions real-time, on your mobile phone screen. You can
          follow this builds while you clashing with the enemy as your second
          monitor.
        </p>
        <br />
        <p style={{ color: COLORS.gray }}>
          <ul>
            <li>All of 143 League of Legends champions and it's builds.</li>
            <li>All builds are fresh and updated for the very live season.</li>
            <li>Build ratings and view counts to see popular ones</li>
            <li>
              {" "}
              Most anticipated and strength-proven builds made by Challenger,
              Diamond and Pro level players at its finest.
            </li>
            <li>
              Simple and sleek design to keep you focused on the game, not the
              build.
            </li>
          </ul>
        </p>
        <br />
        <p style={{ color: COLORS.gray }}>
          Please do not hesitate to contact me with any opinions and
          suggestions. I'll be improving with your feedbacks. Most importantly,
          have fun!​
        </p>
      </div>
      <div>
        <img src={feature} alt="a blank card floating over colorful graphics" />
      </div>
    </content>
  </div>
)

export default Content
