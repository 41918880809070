import React from "react"
import PropTypes from "prop-types"

import headerImage from "../images/header.png"
import Slider from "./slider"
import MobileStoreButton from "react-mobile-store-button"

const APP_STORE_URL =
  "https://itunes.apple.com/us/app/mobabuild-builds-for-league/id1059193502"
const GOGOLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.sercanov.mobabuild&utm_source=landing-page"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>Mobabuild</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      Your League of Legends companion
    </p>
    <Slider />
    <div
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <MobileStoreButton store="ios" url={APP_STORE_URL} />
      <div style={{ marginLeft: 10, marginRight: 10 }} />
      <MobileStoreButton store="android" url={GOGOLE_PLAY_URL} />
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
